import React from 'react'
import Layout from '../../components/layout'
import Validate from '../../components/validate'
import Nav from '../../components/ui/nav'
import { Link } from 'gatsby'
import Footer from '../../components/landing/footer'

// Get the URLs depending on our environment
let redirectUri = 'http://localhost:8888/oauth-callback'
let lambdaUri = 'http://localhost:8888'

const currentEnv = process.env.GATSBY_ENV
if (currentEnv === "prod"){
  redirectUri = 'https://www.paverops.com/oauth-callback'
  lambdaUri = 'https://www.paverops.com'
}
if (currentEnv === "stage"){
  redirectUri = 'https://stage--paverops.netlify.app/oauth-callback'
  lambdaUri = `https://stage--paverops.netlify.app`
}

// markup
const Index = () => {
  return (
    <Layout>
      <main>
        <Validate lambdaUri={lambdaUri} redirectUri={redirectUri}>
          <div className="non-landing">
            <Nav page={"support"} items={[
              {url: "/app/", style: "border", text: "App Home"},
              {url: "/account/", style: "border", text: "Account"}
            ]} />
            <div className="topper">
              <div className="img-wrapper">
                <img src="/images/toppersupport.png" alt="An angular slice of a road being repaired with heavy machinery" />
              </div>
              <div className="deck-wrapper">
                <div className="deck">
                  <h1>
                    Support Hub
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <section className="app-body support">
            <div className="support-page-container">
              <div className="services-wrapper">
                <div className="service-item">
                  <Link to="/support/downloads">
                    <img src="/icons/downloads.svg" />
                    <h2>Downloads & Resources</h2>
                    <p>Access layer templates and schema resources</p>
                  </Link>
                </div>
                <div className="service-item">
                  <Link to="/support/helpcenter">
                    <img src="/icons/help.svg" />
                    <h2>Help Center</h2>
                    <p>Video tutorials and guidance in using various app features</p>
                  </Link>
                </div>
                <div className="service-item">
                  <Link to="/support/feedback">
                    <img src="/icons/techsupport.svg" />
                    <h2>Technical Support</h2>
                    <p>Submit a report about an issue in the app, get technical support, or share an idea</p>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </Validate>
      </main>
    </Layout>
  )
}

export default Index

